.theme-light {
    ion-app {
        transition: all 250ms ease;

        --ion-background-color: var(--ion-color-light);

        --ion-toolbar-background: var(--ion-color-primary);
        --ion-toolbar-color: var(--ion-color-light);

        --highlight-color-focused: var(--ion-color-primary);

        --background-focused: none;
        --ion-item-background-activated: none;

        --ion-color-step-550: var(--ion-color-dark);

        ion-card {
            ion-item {
                --ion-item-background: none;
            }

            ion-card-header {
                background: rgba(86, 185, 163, 0.45);
            }
        }

        ion-spinner {
            color: var(--ion-color-primary);
        }

        ion-item {
            --ion-item-background: var(--ion-color-light);
            --ion-item-color: var(--ion-color-dark);
            --highlight-color-valid: var(--ion-color-primary);
        }

        ion-datetime {
            color: var(--ion-color-dark);
        }

        ion-header {
            ion-toolbar {
                ion-buttons {
                    --ion-color-primary: var(--ion-color-medium-light); // Text for ios
                }

                ion-title {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        ion-button {
            text-transform: unset;
        }
    }

    h3 {
        font-style: italic;
        font-weight: bold;
        margin-top: 10px;
        color: var(--ion-color-primary);
    }

    .secondary-toolbar {
        --background: var(--ion-color-light);
        --color: var(--ion-color-dark);

        ion-title {
            --color: var(--ion-color-medium-light);
        }
    }

    .sc-ion-back-button-ios-h {
        --color: var(--ion-color-primary-contrast);
    }
}

.error-msg {
    color: var(--ion-color-danger) !important;
    --color: var(--ion-color-danger) !important;
    font-weight: bold;
}
